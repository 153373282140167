import React from 'react';
import { SquareProps } from "../models/entities";

export function Square(props: SquareProps) {
    const className = "square" + (props.isWinner ? " winner" : "");

    return (
        <button className={className} onClick={props.onClick}>
            {props.value}
        </button>
    )
}