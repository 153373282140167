// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 20px;
}

ol, ul {
    padding-left: 30px;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}
  
.status {
    margin-bottom: 10px;
}
  
.square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 30px;
    font-weight: bold;
    line-height: 44px;
    height: 44px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 44px;
}
  
.square:focus {
    outline: none;
}

.square.winner {
    background: coral;
}
  
.kbd-navigation .square:focus {
    background: #ddd;
}
  
.game {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
}
  
.game-info {
    margin-left: 20px;
}

button.selected {
    /* border: 2px solid rgb(0, 0, 255); */
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sCAAsC;IACtC,iBAAiB;AACrB","sourcesContent":["body {\n    font: 14px \"Century Gothic\", Futura, sans-serif;\n    margin: 20px;\n}\n\nol, ul {\n    padding-left: 30px;\n}\n\n.board-row:after {\n    clear: both;\n    content: \"\";\n    display: table;\n}\n  \n.status {\n    margin-bottom: 10px;\n}\n  \n.square {\n    background: #fff;\n    border: 1px solid #999;\n    float: left;\n    font-size: 30px;\n    font-weight: bold;\n    line-height: 44px;\n    height: 44px;\n    margin-right: -1px;\n    margin-top: -1px;\n    padding: 0;\n    text-align: center;\n    width: 44px;\n}\n  \n.square:focus {\n    outline: none;\n}\n\n.square.winner {\n    background: coral;\n}\n  \n.kbd-navigation .square:focus {\n    background: #ddd;\n}\n  \n.game {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 10%;\n}\n  \n.game-info {\n    margin-left: 20px;\n}\n\nbutton.selected {\n    /* border: 2px solid rgb(0, 0, 255); */\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
