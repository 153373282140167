import React from 'react';
import { Board } from './Board';
import { State } from '../models/entities';
import { GameProps } from '../models/entities';

class Game extends React.Component<GameProps, State> {
    constructor(props: GameProps) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(null)
            }],
            stepNumber: 0,
            isXNext: true
        };
    }

    handleClick(i: number) {
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }

        squares[i] = getNextPlayer(this.state.isXNext);
        this.setState({
            history: history.concat([{
                squares: squares
            }]),
            stepNumber: history.length,
            isXNext: !this.state.isXNext,
        });
    }

    jumpTo(step: number) {
        this.setState({
            stepNumber: step,
            isXNext: (step % 2) === 0
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);

        const moves = history.map((step, move) => {
            const desc = move ? 
                'Go to move #' + move : 
                'Go to game start';
            return (
                <li key={move}>
                    <button 
                        className={this.state.stepNumber === move ? "selected" : ''}
                        onClick={() => this.jumpTo(move)}>{desc}
                    </button>
                </li>
            );
        });

        let status;
        if (winner) {
            status = 'Winner: ' + winner.winner;
        }
        else {
            status = 'Next player: ' + getNextPlayer(this.state.isXNext);
        }

        return (
            <div className="game">
                <div className="game-board">
                    <Board 
                        squares = {current.squares}
                        winnerSquares = {(winner ? winner.winnerSquares : [])}
                        onClick = {(i: number) => this.handleClick(i)}/>
                </div>
                <div className="game-info">
                    <div>{status}</div>
                    <div>{moves}</div>
                </div>
            </div>
        );
    }
}

function calculateWinner(squares: Array<string>) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return {
                winner: squares[a],
                winnerSquares: lines[i]
            };
        }
    }
    return null;
  }

function getNextPlayer(isXNext : boolean) {
    return (isXNext ? 'X' : 'O')
}

export default Game;