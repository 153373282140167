import React from 'react';
import { Square } from './Square';
import { BoardProps } from "../models/entities";

export function Board(props: BoardProps) {
    const rows = [];

    // an index of a square
    let counter = 0;

    const rowCount = 3;
    const colCount = 3;

    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
        const squares = [];
        for (let colIndex = 0; colIndex < colCount; colIndex++) {
            const i = counter;
            squares.push( 
                <Square 
                    value={props.squares[i]}
                    isWinner = {(props.winnerSquares && props.winnerSquares.includes(i))}
                    onClick = {() => props.onClick(i)}
                />);

            ++counter;
        }

        const row = <div className="board-row">{squares}</div>;
        rows.push(row);
    }

    return (
        <div>
            {rows}
        </div>
    );
}